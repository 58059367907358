import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LicenceService } from 'src/app/services/utils/licence.service';

@Component({
  selector: 'app-bonusexclude-dialog',
  templateUrl: './bonus_excluded-dialog.component.html',
  styleUrls: ['./bonus_excluded-dialog.component.scss']
})
export class BonusExcludedGameComponent  {

  constructor(
    public dialogRef: MatDialogRef<BonusExcludedGameComponent>,
    protected licenceService: LicenceService,
    @Inject(MAT_DIALOG_DATA) public dialogData: {msg:string, dataRefToConfirm:any}) {}

  public redirectUrl:string = null;

  closeDialog(): void {
    this.dialogRef.close();
  }

}
