import { Component, EventEmitter, Input, OnInit, Output, PLATFORM_ID,Inject } from '@angular/core';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SwiperBaseComponent } from '../../swiper-base/swiper-base.component';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { isPlatformBrowser } from '@angular/common';
import { BonusQueueService } from 'src/app/services/wallet/bonus-queue.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-swiper-game-categories-body',
  templateUrl: './swiper-game-categories-body.component.html',
  styleUrls: ['./swiper-game-categories-body.component.scss']
})
export class SwiperGameCategoriesBodyComponent extends SwiperBaseComponent implements OnInit {

  @Input() game: GameItem;
  isBonusExcludedGame: boolean = false;


  constructor(    protected licenceService: LicenceService,
    private bonusQueueService: BonusQueueService,
    @Inject(PLATFORM_ID) private platformId,
    ) { super() }


  ngOnInit(): void {

  }

  onGameClick() {
    this.onSlideClick.emit(this.game);
  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      this.bonusQueueService.getActiveBonus().pipe(takeUntil(this.unsubscribe)).subscribe(bonus => {
        this.isBonusExcludedGame = bonus.excludedGames.includes(this.game?.gameid)
      })
    }
  }
  
}
