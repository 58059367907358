import { Apollo } from 'apollo-angular';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  createNgModule,
  Inject, Injector,
  LOCALE_ID,
  NgModuleRef,
  PLATFORM_ID,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ExapnsionPanels, ExpansionPanelItem, GameCategory } from 'src/app/apollo/models/base-models';
import { FRONT_PAGE_EXPANSION_PANEL } from 'src/app/apollo/front-page-games/front-page-games';
import { DomSanitizer } from '@angular/platform-browser';
import { ToggleMenuBackService } from 'src/app/services/utils/toggle-menu-back.service';
import { LoaderService } from 'src/app/services/utils/loader/loader.service';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { OpenDesktopDrawerService } from 'src/app/services/utils/open-desktop-drawer.service';
import { MinimizeSiteService } from 'src/app/services/utils/hide-footer.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ClonerService } from 'src/app/services/utils/clone-object.service';
import { COUNTRY_ID, LICENCE } from 'src/app/global.tokens';
import { FilterGamesService } from 'src/app/services/utils/filter-games.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { missionsTrx, reactivationTrx, searchGameTrx, tournamentsTrx } from 'src/app/router-translation.labels';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import { TournamentsCarouselComponent } from './tournaments-carousel/tournaments-carousel.component';
import { isPlatformServer } from '@angular/common';
import { BasePageComponentWithDialogs } from '../base-page/base-page.component';
import { MatDialog } from '@angular/material/dialog';
import { MissionsCarouselComponent } from './missions-carousel/missions-carousel.component';
import { combineLatest, timer } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { PaymentListService } from 'src/app/services/payment/methods/payment-list.service';
import { BonusListService } from 'src/app/services/payment/bonus/bonus-list.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { SearchConfig, swiperConfig, SearchConfigFilterItem, SearchGameService, SearchDataItem } from 'src/app/services/games/search-game.service';
import { ReactivationCarouselComponent } from './reactivation-carousel/reactivation-carousel.component';
import { loadChatScript } from 'src/app/utils/load-chat';
// import { XmasCarouselComponent } from './xmas-carousel/xmas-carousel.component';


@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent extends BasePageComponentWithDialogs implements AfterViewInit {

  categories: GameCategory[];
  operators: GameCategory[];
  searchCategories: SearchConfigFilterItem[];
  searchSwiper: SearchConfigFilterItem;
  expansionPanel: ExpansionPanelItem[];
  public swiperCategories: GameCategory[];
  public tournamentsAllTrx = this.translationConfig.getTranslation(tournamentsTrx);
  public missionsAllTrx = this.translationConfig.getTranslation(missionsTrx);
  public reactivationAllTrx = this.translationConfig.getTranslation(reactivationTrx);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private apollo: Apollo,
    private toggleBackService: ToggleMenuBackService,
    private loadingService: LoaderService,
    public detectDeviceService: DetectDeviceService,
    public openDrawerService: OpenDesktopDrawerService,
    public minimizeSiteService: MinimizeSiteService,
    private frontPageGamesService: FrontPageGamesService,
    private cloneService: ClonerService,
    private FilterGamesService: FilterGamesService,
    protected translationConfig: TranslationConfig,
    private prevUrlService: DialogClosePreviousUrl,
    private paymentListService: PaymentListService,
    private bonusListService: BonusListService,
    private loginStatusService: LoginStatusService,
    protected licenceService: LicenceService,
    private searchGamesService: SearchGameService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(COUNTRY_ID) public countryId: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(WINDOW) protected windowRef: Window,

    private _injector: Injector,

    dialog: MatDialog,
    private detectDesktopService: DetectDeviceService,
  ) { super(dialog, detectDesktopService); }

  @ViewChild('tournaments', { static: false, read: ViewContainerRef })
  public tournamentsRef: ViewContainerRef;

  @ViewChild('missions', { static: false, read: ViewContainerRef })
  public missionsRef: ViewContainerRef;

  @ViewChild('reactivation', { static: false, read: ViewContainerRef })
  public reactivationRef: ViewContainerRef;

  // @ViewChild('xmas', { static: false, read: ViewContainerRef })
  // public xmasRef: ViewContainerRef;

  protected isShowTournamentsModulePlaceholder = true;
  protected isShowRectivationModulePlaceholder = true;
  protected isShowXmasModulePlaceholder = true;
  protected isShowMissionsModulePlaceholder = true;

  ngAfterViewInit(): void {
    timer(300).pipe(take(1)).subscribe(() => {
      this.windowRef.scrollTo(0, this.prevUrlService.getScrollPosition());
      this.loginStatusService.getIfUserLogged().pipe(take(1), takeUntil(this.unsubscribe)).subscribe(resp => {
        combineLatest({
          // here pay list income
          paymentResp: this.paymentListService.fetchPayment(resp.fullInfo?.country || 'en').pipe(
            filter(resp => resp.length !== 0),
            take(1)),
          // here bonus list income
          // bonusResp: this.bonusListService.fetchData().pipe(
          //   filter(resp => resp.length !== 0),
          //   take(1))
        }
        ).subscribe((resp) => { })
      })
    })
  }

  private _openLazyLoadedComponent<T>(
    importedFile: T,
    componentToOpen: Type<TournamentsCarouselComponent>
  ): void {
    this.isShowTournamentsModulePlaceholder = false;
    const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    this.tournamentsRef.createComponent(componentToOpen, { ngModuleRef: moduleRef });
  }
  private _openLazyLoadedComponentMissions<T>(
    importedFile: T,
    componentToOpenMissions: Type<MissionsCarouselComponent>
  ): void {
    this.isShowMissionsModulePlaceholder = false;
    const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    this.missionsRef.createComponent(componentToOpenMissions, { ngModuleRef: moduleRef });
  }
  private _openLazyLoadedComponentReactivation<T>(
    importedFile: T,
    componentToOpenMissions: Type<ReactivationCarouselComponent>
  ): void {
    this.isShowRectivationModulePlaceholder = false;
    const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
    const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
    this.reactivationRef.createComponent(componentToOpenMissions, { ngModuleRef: moduleRef });
  }

  // private _openLazyLoadedComponentXmas<T>(
  //   importedFile: T,
  //   componentToOpenXmas: Type<XmasCarouselComponent>
  // ): void {
  //   this.isShowXmasModulePlaceholder = false;
  //   const module: Type<T> = (importedFile as any)[Object.keys(importedFile)[0]];
  //   const moduleRef: NgModuleRef<T> = createNgModule(module, this._injector);
  //   this.xmasRef.createComponent(componentToOpenXmas, { ngModuleRef: moduleRef });
  // }

  ngOnInit(): void {
    if (!isPlatformServer(this.platformId)) {
      import('../../components/tournaments/tournaments-carousel.dynamic.module').then((importedFile) => {
        const componentToOpen =
          importedFile.TournamentsCarouselDynamicModule.components.dynamicComponent;
        this._openLazyLoadedComponent(importedFile, componentToOpen);
      });

      import('../../components/missions/missions-carousel.dynamic.module').then((importedFile) => {
        const componentToOpenMissions =
          importedFile.MissionsCarouselDynamicModule.components.dynamicComponent;
        this._openLazyLoadedComponentMissions(importedFile, componentToOpenMissions);
      });

      import('../../components/reactivation/reactivation-carousel.dynamic.module').then((importedFile) => {
        const componentToOpenMissions =
          importedFile.ReactivationCarouselDynamicModule.components.dynamicComponent;
        this._openLazyLoadedComponentReactivation(importedFile, componentToOpenMissions);
      });

      // import('../../components/xmas/xmas-carousel.dynamic.module').then((importedFile) => {
      //   const componentToOpenXmas =
      //     importedFile.XmasCarouselDynamicModule.components.dynamicComponent;
      //   this._openLazyLoadedComponentXmas(importedFile, componentToOpenXmas);
      // });
    }
    // this.loadingService.show();
    this.toggleBackService.hideBack();
    // this.titleService.setTitle($localize`:@@title-front-page:Lemon Casino - best games online`);

    this.frontPageGamesService.fetchData().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      let categories = this.cloneService.deepClone<GameCategory[]>(response.pagesCategories[0].categories);
      this.setSEOTags(response.pagesCategories[0].seo?.metaTitle, response.pagesCategories[0].seo?.metaDescription);

      this.categories = this.FilterGamesService.filterCategories(categories);

      this.operators = response.operators[0]?.categories;
      this.loadingService.hide();
    });

    this.searchGamesService.getFilters().pipe(
      takeUntil(this.unsubscribe),
      map((filters) => this.searchGamesService.setFilters(filters, SearchConfig)),
      tap((searchConfig) => this.searchCategories = searchConfig.searchFilters),
      map((searchConfig) => searchConfig.searchFilters.find((item) => item.name == 'categories')),
      map((searchConfig) => {
        if (searchConfig && searchConfig.data) {
          searchConfig.data = searchConfig.data.filter((item) => !!item.displayName);
        }
        return searchConfig;
      })
    ).subscribe((searchSwiper) => {
      this.searchSwiper = searchSwiper;
    });

    this.apollo
      .watchQuery<ExapnsionPanels>({
        query: FRONT_PAGE_EXPANSION_PANEL,
        variables: {
          locale: this.locale,
          licenseName: this.licence
        }
      })
      .valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
        const expansionPanelResponse = response.data.expansionPanels?.filter(item => item.licence !== 'all')[0] || response.data.expansionPanels[0];
        const resp: ExpansionPanelItem[] = this.cloneService.deepClone(expansionPanelResponse.item);

        this.expansionPanel = resp.map(item => {
          if (!!item && !!item.content) {
            item.content.text = item.content.html.replace(/<[^>]+>/g, '');
            item.content.safehtml = this.sanitizer.bypassSecurityTrustHtml(item.content.html);
            return item;
          }
        });
      });

    this.loginStatusService.getIfUserLogged().pipe(takeUntil(this.unsubscribe)).subscribe((status) => {
      let roles = status.fullInfo?.roles?.map((item: string) => item.toLowerCase());
      const hasVip = !!roles.find((item: string) => item.includes('vip')) || !!roles.some((item: string) => ["EX_VIP_ROW", "EX_VIP_PL", "EX_SVIP_ROW", "EX_SVIP_PL"].includes(item.toUpperCase()));
      if (hasVip) {
        loadChatScript(`vip-${this.locale}`, status.username, status.fullInfo.first_name);
      } else if (this.locale !== "pl") {
        loadChatScript(this.locale, status.username, status.fullInfo.first_name);
      }
      console.log("chat enabled 2")
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const componentPosition = this.elementRef?.nativeElement?.querySelector('#expansionPanel')?.offsetTop;
    if (!componentPosition) return;
    const scrollPosition = window.pageYOffset + window.innerHeight;
    if (scrollPosition >= componentPosition) {
      if (this.expansionPanel) {
        this.expansionPanel.forEach(element => {
          element.display = true;
        });
      }
    }
  }

  onFilterSelect(item: SearchDataItem) {
    this.searchGamesService.saveSearchHistory('');

    for (let category of this.searchCategories) {
      const categoryItem = category.data.find((_item) => _item.name === item.name);

      if (categoryItem) {
        categoryItem.selected = true;
        break;
      }
    }

    this.searchGamesService.setSearchFilters(this.searchCategories)
    this.router.navigate(['/' + searchGameTrx])
  }

  onSwiperCategoryClick() {
    this.router.navigate(['/' + searchGameTrx]);
  }

}
